






import { Component, Vue } from 'vue-property-decorator';
import Error from '../../../components/contents/Error.vue';

@Component({
  components: {
    Error,
  },
})
export default class LoginForgetPasswordError extends Vue {
}
